// @flow

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  AccountLoadCollection: ['collection'],
  AccountSetSimulation: [
    'collection',
    'simulation',
    'forceReload',
    'runTrigger',
    'nextUrl'
  ],
  AccountSetTrigger: ['trigger', 'runTrigger', 'collectionId', 'simulationId'],
  AccountSendNotification: ['payload'],
  AccountSetHorizontal: ['horiz'],
  AccountSetCollection: ['collection'],
  AccountSetSimulations: ['simulations'],
  AccountSuccess: ['data'],
  AccountFailure: ['error'],
  AccountLogout: [null]
});

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  collection: null,
  simulations: null,
  data: null,
  error: null,
  fetching: false
});

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = state =>
  state.merge({ error: null, fetching: true, account: null });

// we've successfully completed an API call.
export const success = (state, { data }) =>
  state.merge({ fetching: false, error: null, data });

// we've had a problem with our API call.
export const failure = (state, { error }) =>
  state.merge({
    fetching: false,
    error: error ? error : 'Unknown Error',
    data: null
  });

export const reset = state => INITIAL_STATE;

export const setCollection = (state: Object, { collection }: Object) =>
  state.merge({ collection });

export const setSimulations = (state: Object, { simulations }: Object) =>
  state.merge({ simulations });

export const setToken = (state: Object, { token }: Object) =>
  state.merge({ token });

export const doNothing = (state, { payload }) => state;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACCOUNT_LOAD_COLLECTION]: request,
  [Types.ACCOUNT_SET_SIMULATION]: request,
  [Types.ACCOUNT_SET_TRIGGER]: request,
  [Types.ACCOUNT_SEND_NOTIFICATION]: request,
  [Types.ACCOUNT_SET_HORIZONTAL]: request,
  [Types.ACCOUNT_SET_COLLECTION]: setCollection,
  [Types.ACCOUNT_SET_SIMULATIONS]: setSimulations,
  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,
  [Types.ACCOUNT_LOGOUT]: reset
});

/* ------------- Selectors ------------- */

// Is the current account logged in?
//export const isLoggedIn = (loginState) => loginState.account !== null;
//export const isLoggedIn = (account) => account !== null;
