// @flow

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  AppSetUpdateAvailable: [],
  AppSetCached: []
});

export const AppTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  updateAvailable: false,
  cached: false
});

/* ------------- Reducers ------------- */

export const setUpdate = state => state.merge({ updateAvailable: true });

export const setCached = state => state.merge({ cached: true });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.APP_SET_UPDATE_AVAILABLE]: setUpdate,
  [Types.APP_SET_CACHED]: setCached
});

/* ------------- Selectors ------------- */
