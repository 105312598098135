import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Navigation from './Navigation';
import UpdateSnackbar from './Components/UpdateSnackbar';

const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Gotham',
      '-apple-system',
      'Raleway',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#4A90E2'
    },
    secondary: {
      // main: '#FF1744'
      main: '#2352E8'
    }
  }
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Navigation />
      <UpdateSnackbar />
    </MuiThemeProvider>
  );
}

export default App;
