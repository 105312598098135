import { call, put, select } from 'redux-saga/effects';
import firebase from 'firebase';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { push } from 'connected-react-router';
import { path } from 'ramda';

import {
  COLLECTIONS_COLLECTION,
  SIMULATIONS_COLLECTION,
  USER_PROFILE,
  WEBSITE_CONFIG_ID,
  ENVIRONMENT
} from '../Config';

import AccountActions from '../Redux/AccountRedux';

const reduxSagaFirebase = new ReduxSagaFirebase(firebase);

const getProfile = state => state.firebase.profile;

function getIdToken() {
  return firebase.auth().currentUser.getIdToken();
}

function _setUserCollection(uid, collection) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set(
      {
        collection
      },
      { merge: true }
    );
}

function _setUserSimulation(uid, collection, simulation) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set(
      {
        collection,
        simulation
      },
      { merge: true }
    );
}

function _setUserProfile(uid, profile) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set(profile);
}

export function* setCollection(api, { collection, runTrigger }) {
  const shouldRunTrigger = runTrigger || true;
  console.log('setCollection', collection, shouldRunTrigger);

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  if (uid) {
    yield call(_setUserCollection, uid, collection);
    yield put(AccountActions.AccountSuccess(true));
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'));
  }
}

/**
 * Loads Collection and Simulation to Redux
 *
 * @param {*} api
 * @param {*} params
 */
export function* loadCollection(api, { collection }) {
  console.log('loadCollection', collection);

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  if (uid) {
    // If we have been passed a collection.  Load that collection.
    const collectionSnapshot = yield call(
      reduxSagaFirebase.firestore.getDocument,
      `${COLLECTIONS_COLLECTION}/${collection}`
    );
    const collectionData = collectionSnapshot.data();
    // console.log(collectionData);
    yield put(AccountActions.AccountSetCollection(collectionData));

    // Preload the Avaialable Simulations
    const simulationsSnapshot = yield call(
      reduxSagaFirebase.firestore.getCollection,
      firebase
        .firestore()
        .collection(SIMULATIONS_COLLECTION)
        .where('memberOf', 'array-contains', collection)
    );
    let simulations = {};
    // Loop Over Returned Data
    simulationsSnapshot.forEach(snapshot => {
      const snapshotData = snapshot.data();
      simulations[snapshotData.id] = snapshotData;
      // console.log(snapshotData);
    });

    // Set Simulation in Redux Store
    yield put(AccountActions.AccountSetSimulations(simulations));

    yield put(AccountActions.AccountSuccess(true));
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'));
  }
}

/**
 * Set's User's Profile with collection, simulation
 * (optional) Triggers 1st Trigger
 *
 * @param {*} api
 * @param {*} params
 */
export function* setSimulation(
  api,
  { collection, simulation, forceReload, runTrigger, nextUrl }
) {
  const shouldRunTrigger = runTrigger || true;
  const shouldForceReload = forceReload || false;
  console.log(
    'setSimulation',
    collection,
    simulation,
    shouldForceReload,
    shouldRunTrigger,
    nextUrl
  );

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  if (uid) {
    // // Load Current User Profile
    // const profileSnapshot = yield call(
    //   reduxSagaFirebase.firestore.getDocument,
    //   `${USER_PROFILE}/${uid}`
    // );
    // let profile = profileSnapshot.data();
    let profile = yield select(getProfile);
    profile = Object.assign({}, profile);

    profile.configId = WEBSITE_CONFIG_ID;
    profile.environment = ENVIRONMENT;

    // If we have been passed a collection.  Load that collection.
    if (collection) {
      profile.collection = collection;
      // if (
      //   shouldForceReload ||
      //   path(['collection', 'id'], profile) !== collection
      // ) {
      //   const collectionSnapshot = yield call(
      //     reduxSagaFirebase.firestore.getDocument,
      //     `${COLLECTIONS_COLLECTION}/${collection}`
      //   );
      //   const collectionData = collectionSnapshot.data();
      //   console.log(collectionData);
      //   // Add Collection to Profile
      //   profile.collection = collectionData;

      //   // yield put(AccountActions.AccountSetCollection(collectionData));

      //   // // Preload the Avaialable Simulations
      //   // const simulationsSnapshot = yield call(
      //   //   reduxSagaFirebase.firestore.getCollection,
      //   //   firebase
      //   //     .firestore()
      //   //     .collection(SIMULATIONS_COLLECTION)
      //   //     .where('memberOf', 'array-contains', collection)
      //   // );
      //   // let simulations = {};
      //   // // Loop over Collection Simulations
      //   // // Loop Over Returned Data
      //   // simulationsSnapshot.forEach(snapshot => {
      //   //   const snapshotData = snapshot.data();
      //   //   simulations[snapshotData.id] = snapshotData;
      //   //   console.log(snapshotData);
      //   // });

      //   // // Save Simulations
      //   // profile.simulations = simulations;

      //   // // Set Simulation in Redux Store
      //   // yield put(AccountActions.AccountSetSimulations(simulations));
      // }
    } else {
      // Collection was not passed, update profile
      delete profile.collection;
      // delete profile.simulations;
    }

    // If we were passed a simulation, see if we need to load it
    if (simulation) {
      profile.simulation = simulation;
      // if (
      //   shouldForceReload ||
      //   path(['simulation', 'id'], profile) !== simulation
      // ) {
      //   // // Simulation has changed, check to see if we have preloaded it.
      //   // if (
      //   //   profile.simulations &&
      //   //   profile.simulations.hasOwnProperty(simulation)
      //   // ) {
      //   //   profile.simulation = profile.simulations[simulation];
      //   // } else {
      //     // Simulation is not preloaded.  Load it.
      //     const simulationSnapshot = yield call(
      //       reduxSagaFirebase.firestore.getDocument,
      //       `${SIMULATIONS_COLLECTION}/${simulation}`
      //     );
      //     const simulationData = simulationSnapshot.data();
      //     console.log('loaded simulation', simulationData);
      //     profile.simulation = simulationData;
      //   }
    } else {
      delete profile.simulation;
    }

    // Delete extra params.
    delete profile.isEmpty;
    delete profile.isLoaded;

    // Save Updated User Profile
    yield call(
      reduxSagaFirebase.firestore.setDocument,
      `${USER_PROFILE}/${uid}`,
      profile
    );

    if (simulation && shouldRunTrigger) {
      // Load Simulation Contents
      const simulationSnapshot = yield call(
        reduxSagaFirebase.firestore.getDocument,
        `${SIMULATIONS_COLLECTION}/${simulation}`
      );
      const simulationData = simulationSnapshot.data();
      const trigger = Object.assign({}, simulationData.triggers[0], {
        idx: 0
      });
      yield call(setTrigger, api, { trigger, runTrigger: true });
    }

    // Send Success back to client
    yield put(AccountActions.AccountSuccess(true));

    // Check to see if we need to change pages.
    if (nextUrl) {
      console.log('navigating to', nextUrl);
      yield put(push(nextUrl));
    }
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'));
  }
}

export function* setTrigger(
  api,
  { trigger, runTrigger, collectionId, simulationId }
) {
  const shouldRunTrigger = runTrigger || true;
  console.log('setTrigger Saga', trigger.idx, collectionId, simulationId);
  // console.log('setTrigger', trigger.idx, shouldRunTrigger);

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  if (uid) {
    let profile = yield select(getProfile);
    profile = Object.assign({}, profile, { trigger });
    // Delete extra params.
    delete profile.isEmpty;
    delete profile.isLoaded;

    // Always Set configId and Enviroment
    profile.configId = WEBSITE_CONFIG_ID;
    profile.environment = ENVIRONMENT;

    if (collectionId !== undefined) {
      // console.log('updating collectionId', collectionId);
      profile.collection = collectionId;
    }
    if (simulationId !== undefined) {
      // console.log('updating simulationId', simulationId);
      profile.simulation = simulationId;
    }

    // console.log('setting profile', profile);

    // console.log('new profile', uid, JSON.stringify(profile.trigger, null, 2));
    yield call(_setUserProfile, uid, profile);

    // yield call(_setUserTrigger, uid, trigger);

    if (trigger.phoneAction && trigger.phoneAction.data && shouldRunTrigger) {
      const token = yield call(getIdToken);
      console.log(
        'sendNotification',
        JSON.stringify(trigger.phoneAction, null, 2)
      );
      const results = yield call(
        api.sendNotification,
        trigger.phoneAction,
        token
      );
      // console.log('results', results);

      // yield call(sendNotification, api, { payload: trigger.phoneAction });
    }

    yield put(AccountActions.AccountSuccess(true));
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'));
  }
}

export function* setHorizontal(api, { horiz }) {
  console.log('setHorizontal', horiz);

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  if (uid) {
    let profile = yield select(getProfile);
    profile = Object.assign({}, profile, { horiz });
    // Delete extra params.
    delete profile.isEmpty;
    delete profile.isLoaded;

    yield call(_setUserProfile, uid, profile);

    yield put(AccountActions.AccountSuccess(true));
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'));
  }
}

export function* sendNotification(api, { payload }) {
  console.log('sendNotification', payload);
  try {
    const token = yield call(getIdToken);
    const results = yield call(api.sendNotification, payload, token);
    yield put(AccountActions.AccountSuccess(results));
  } catch (error) {
    console.log('error', error);
    yield put(AccountActions.AccountFailure(error));
  }
}
