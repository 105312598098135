import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link, Switch } from 'react-router-dom';
import firebase from 'firebase';
import ReactGA from 'react-ga';
import Loadable from 'react-loadable';

// import asyncComponent from '../Components/AsyncComponent';

/** Import Each Page Component On-Demand * */

const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ '../Containers/Home'),
  loading: () => null,
  modules: ['home']
});
const AsyncSimulations = Loadable({
  loader: () =>
    import(/* webpackChunkName: "simulations" */ '../Containers/Simulations'),
  loading: () => null,
  modules: ['simulations']
});
const AsyncSelectInterface = Loadable({
  loader: () =>
    import(/* webpackChunkName: "selectinterface" */ '../Containers/SelectInterface'),
  loading: () => null,
  modules: ['selectinterface']
});
const AsyncControlPanel = Loadable({
  loader: () =>
    import(/* webpackChunkName: "controlpanel" */ '../Containers/ControlPanel'),
  loading: () => null,
  modules: ['controlpanel']
});
const AsyncDigitalDisplayPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "digitaldisplaypage" */ '../Containers/DigitalDisplayPage'),
  loading: () => null,
  modules: ['digitaldisplaypage']
});
const AsyncPresentationDisplayPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "presentationdisplaypage" */ '../Containers/PresentationDisplayPage'),
  loading: () => null,
  modules: ['presentationdisplaypage']
});
const AsyncAuxDisplayPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "auxdisplaypage" */ '../Containers/AuxDisplayPage'),
  loading: () => null,
  modules: ['auxdisplaypage']
});

const AsyncSales = Loadable({
  loader: () => import(/* webpackChunkName: "sales" */ '../Containers/Sales'),
  loading: () => null,
  modules: ['sales']
});
const AsyncDownloads = Loadable({
  loader: () =>
    import(/* webpackChunkName: "downloads" */ '../Containers/Downloads'),
  loading: () => null,
  modules: ['downloads']
});
const AsyncError = Loadable({
  loader: () => import(/* webpackChunkName: "error" */ '../Containers/Error'),
  loading: () => null,
  modules: ['error']
});
const AsyncTouch = Loadable({
  loader: () => import(/* webpackChunkName: "touch" */ '../Containers/Touch'),
  loading: () => null,
  modules: ['touch']
});
const AsyncTouchSimulation = Loadable({
  loader: () =>
    import(/* webpackChunkName: "touchsimulation" */ '../Containers/TouchSimulation'),
  loading: () => null,
  modules: ['touchsimulation']
});

// const AsyncHome = asyncComponent(() => import('../Containers/Home'));
// const AsyncSimulations = asyncComponent(() =>
//   import('../Containers/Collection')
// );
// const AsyncSales = asyncComponent(() => import('../Containers/Sales'));
// const AsyncDownloads = asyncComponent(() => import('../Containers/Downloads'));
// const AsyncError = asyncComponent(() => import('../Containers/Error'));

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    // console.log('GAListener', location.pathname);
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

const Navigation = () => (
  <GAListener>
    <Switch>
      <Route exact path="/:mode(innovation|bic)" component={AsyncHome} />
      {/* Innovation Center Routes */}
      <Route
        exact
        path="/:mode(innovation|bic)/:collection/simulations"
        component={AsyncSimulations}
      />
      <Route
        exact
        path="/:mode(innovation|bic)/:collection/selectInterface"
        component={AsyncSelectInterface}
      />
      <Route
        exact
        path="/:mode(innovation|bic)/:collection/:simulation/controlPanel/:notrigger?"
        component={AsyncControlPanel}
      />

      {/* Innovation Display Pages */}
      <Route
        exact
        path="/:mode(innovation|bic)?/:collection/:simulation/display/:notrigger?"
        component={AsyncDigitalDisplayPage}
      />
      <Route
        exact
        path="/:mode(innovation|bic)?/:collection/:simulation/presentation/:notrigger?"
        component={AsyncPresentationDisplayPage}
      />
      <Route
        exact
        path="/:mode(innovation|bic)?/:collection/:simulation/aux/:notrigger?"
        component={AsyncAuxDisplayPage}
      />

      {/* Downloads Page */}
      <Route
        exact
        path="/:mode(innovation|bic)/downloads"
        component={AsyncDownloads}
      />

      {/* Sales Enablement Routes (BIC) */}
      <Route
        exact
        path="/:mode(innovation|bic)?/:collection/:simulation/sales/:notrigger?"
        component={AsyncSales}
      />

      {/* Touch Screen Routes */}
      <Route
        exact
        path="/:mode(innovation|bic)/:collection/touch"
        component={AsyncTouch}
      />
      <Route exact path="/:collection/touch" component={AsyncTouch} />
      <Route
        exact
        path="/:mode(innovation|bic)?/:collection/:simulation/TouchSimulation/:notrigger?"
        component={AsyncTouchSimulation}
      />
      <Route
        exact
        path="/:collection/:simulation/TouchSimulation/:notrigger?"
        component={AsyncTouchSimulation}
      />

      {/* Finally, catch all unmatched routes */}
      <Route path="*" component={AsyncError} />
    </Switch>
  </GAListener>
);
export default Navigation;
