import { takeLatest, takeEvery } from 'redux-saga/effects';

/* ------------- Types ------------- */

import { AccountTypes } from '../Redux/AccountRedux';
// import { ApplicationTypes } from '../Redux/ApplicationRedux';
// import { TranslationTypes } from '../Redux/TranslationRedux';

/* ------------- Sagas ------------- */

import {
  setSimulation,
  setTrigger,
  sendNotification,
  setHorizontal,
  loadCollection
} from './AccountSagas';
// import { create, update } from './ApplicationSagas';
// import { loadTranslation } from './TranslationSagas';

/* ------------- API ------------- */
import API from '../Services/api';
// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
//const api = DebugSettings.useFixtures ? FixtureAPI : API.create()
export const api = API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield [
    takeLatest(AccountTypes.ACCOUNT_LOAD_COLLECTION, loadCollection, api),
    takeLatest(AccountTypes.ACCOUNT_SET_SIMULATION, setSimulation, api),
    takeLatest(AccountTypes.ACCOUNT_SET_HORIZONTAL, setHorizontal, api),
    takeLatest(AccountTypes.ACCOUNT_SET_TRIGGER, setTrigger, api),
    takeLatest(AccountTypes.ACCOUNT_SEND_NOTIFICATION, sendNotification, api)
  ];
}
