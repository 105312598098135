import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore'; // make sure you add this for firestore

import { reduxFirestore } from 'redux-firestore';

import { reactReduxFirebase } from 'react-redux-firebase';

import createSagaMiddleware from 'redux-saga';

import { USER_PROFILE, FIREBASE_CONFIG } from '../Config';

import rootReducer from './index';
import rootSaga from '../Sagas';

export default (url = '/') => {
  // Create a history depending on the environment
  const history = createBrowserHistory();
  console.log('creating store');

  // initialize firebase instance once
  if (!firebase.apps.length) {
    console.log('initilizing firebase');
    firebase.initializeApp(FIREBASE_CONFIG);
    // Initialize Firestore with timeshot settings
    firebase.firestore().settings({ timestampsInSnapshots: true });
    // firebase.firestore().enablePersistence();
    // firebase.firestore.setLogLevel('debug');
  }

  const reactReduxEnhancer = reactReduxFirebase(firebase, {
    userProfile: USER_PROFILE,
    useFirestoreForProfile: true // Store in Firestore instead of Real Time DB
  });

  const enhancers = [reactReduxEnhancer, reduxFirestore(firebase)];

  // Dev tools are helpful
  // if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
  // }

  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = { firebase: { authError: null } };

  // Create the store
  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./index', () => {
        const nextRootReducer = require('../Redux').default;
        store.replaceReducer(nextRootReducer);
      });
    }
  }

  // kick off root saga
  sagaMiddleware.run(rootSaga);

  return {
    store,
    history
  };
};
