import config from './config.json';

export const SALES_URL_PARAM = 'bic';
export const INNOVATION_CENTER_URL_PARAM = 'innovation';

export const DIGITAL_SIGN_PROPERTY = 'interactiveDisplay';
export const PRESENTATION_SIGN_PROPERTY = 'triggerDisplay';
export const AUX_SIGN_PROPERTY = 'auxDisplay';

// export const API_BASE_URL =
//   'https://us-central1-forgedx-ideacloud.cloudfunctions.net/app/';
// export const API_BASE_URL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://us-central1-forgedx-ideacloud.cloudfunctions.net/app/'
//     : 'http://localhost:5000/forgedx-ideacloud/us-central1/app';
// export const FIREBASE_CONFIG = {
//   apiKey: 'AIzaSyClfzyRgonfvG2MKs6qh-PaYi-iQEz4slc',
//   authDomain: 'forgedx-ideacloud.firebaseapp.com',
//   databaseURL: 'https://forgedx-ideacloud.firebaseio.com',
//   projectId: 'forgedx-ideacloud',
//   storageBucket: 'forgedx-ideacloud.appspot.com',
//   messagingSenderId: '465998288192'
// };

// export const API_BASE_URL =
//   'https://us-central1-forgedx-ideacloud-v2.cloudfunctions.net/app/';
export const API_BASE_URL =
  window.location.href.indexOf('localhost') !== -1
    ? 'http://localhost:5000/forgedx-ideacloud-v2/us-central1/app'
    : 'https://us-central1-forgedx-ideacloud-v2.cloudfunctions.net/app/';
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyB_CijdghSyGDhysIifPudngDDKHCIBd78',
  authDomain: 'forgedx-ideacloud-v2.firebaseapp.com',
  databaseURL: 'https://forgedx-ideacloud-v2.firebaseio.com',
  projectId: 'forgedx-ideacloud-v2',
  storageBucket: 'forgedx-ideacloud-v2.appspot.com',
  messagingSenderId: '77821651729'
};
export const ENVIRONMENT = config.environment;
export const PRODUCTION = ENVIRONMENT === 'production';
// window.location.href.indexOf('192.168') === -1 &&
// window.location.href.indexOf('localhost') === -1 &&
// window.location.href.indexOf('-qa.') === -1;

export const USER_PROFILE = 'users';
export const WEBSITE_CONFIG_COLLECTION = PRODUCTION ? 'config' : 'config_qa';

export const COLLECTIONS_COLLECTION = PRODUCTION
  ? 'collections'
  : 'collections_qa';
export const SIMULATIONS_COLLECTION = PRODUCTION
  ? 'simulations'
  : 'simulations_qa';

export const GOOGLE_ANALYTICS = config.googleAnalytics; // eslint-disable-line prefer-destructuring
export const COMPANY = config.id;
export const WEBSITE_CONFIG_ID = config.id;
